export default function contactApiInit() {
  $('form#benchmarking-form').submit((event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      event.target.classList.add('was-validated');
      event.stopPropagation();
      return;
    }

    const firstName = $(event.target).find('input[id=firstname]').val();
    const lastName = $(event.target).find('input[id=lastname]').val();
    const email = $(event.target).find('input[id=email]').val();

    const paramData = {
      type: 'Benchmarking Request - Desktop',
      name: `${firstName} ${lastName}`,
      email: `${email}`,
    };

    $.post(`${_APP_API_}/v2/contact`, paramData, () => {
      $('.contact_done').removeClass('hidden');
      $('form#benchmarking-form').addClass('hidden');
      $('form#benchmarking-form-mobile').addClass('hidden');
    });
  });

  $('form#benchmarking-form-mobile').submit((event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      event.target.classList.add('was-validated');
      event.stopPropagation();
      return;
    }

    const firstName = $(event.target).find('input[id=firstname_mobile]').val();
    const lastName = $(event.target).find('input[id=lastname_mobile]').val();
    const email = $(event.target).find('input[id=email_mobile]').val();

    const paramData = {
      type: 'Benchmarking Request - Mobile',
      name: `${firstName} ${lastName}`,
      email: `${email}`,
    };

    const params = new URLSearchParams(Object.entries(paramData));

    $.post(`${_APP_API_}/v2/contact`, params, () => {
      $('.contact_done').removeClass('hidden');
      $('form#benchmarking-form').addClass('hidden');
      $('form#benchmarking-form-mobile').addClass('hidden');
    });
  });

  $('form#contact').submit((event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      event.target.classList.add('was-validated');
      event.stopPropagation();
      return;
    }

    const firstName = $(event.target).find('input[id=firstname]').val();
    const lastName = $(event.target).find('input[id=lastname]').val();
    const email = $(event.target).find('input[id=email]').val();
    const organization = $(event.target).find('input[id=organization]').val();
    const contactMessage = $(event.target).find('textarea[id=comment]').val();

    const paramData = {
      type: 'General Inquiry',
      name: `${firstName} ${lastName}`,
      email: `${email}`,
      org: `${organization}`,
      message: `${contactMessage}`,
    };

    $.post(`${_APP_API_}/v2/contact`, paramData, () => {
      $('.contact_done').removeClass('hidden');
      $('form#contact').addClass('hidden');
    });
  });

  $('form#newsletter, form#newsletterModal').submit((event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      event.target.classList.add('was-validated');
      event.stopPropagation();
      return;
    }

    const email = $(event.target).find('input[type=email]').val();

    const paramData = {
      type: 'Newsletter Signup',
      email: `${email}`,
    };

    $.post(`${_APP_API_}/v2/contact`, paramData, () => {
      $('.newsletter_done').removeClass('hidden');
      $('.newewsletter_start').addClass('hidden');
    });
  });
}
